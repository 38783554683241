.cell {
    width: 7vw;
    height: 7vw;
    max-width: 90px;
    max-height: 90px;
    font-size: min(6vw, 50px);
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    caret-color: transparent;
    color: aliceblue;
    background-color: rgb(42, 42, 42);
    border: rgb(34, 34, 34);
    border-width: min(0.7vw, 10px);
    border-style: solid;
}

.correct {
    background-color: rgb(104, 170, 5);
    border: rgb(67, 110, 1);
    border-width: min(0.7vw, 10px);
    border-style: solid;
}

.wrong {
    background-color: rgb(179, 13, 13);
    border: rgb(134, 10, 10);
    border-width: min(0.7vw, 10px);
    border-style: solid;
}

.contains {
    background-color: rgb(211, 139, 5);
    border: rgb(155 102 3);
    border-width: min(0.7vw, 10px);
    border-style: solid;
}
.game-code {
    margin: 0 10px 0 10px;
    font-size: 25px;
    background-color: rgb(71, 87, 231);
    padding: 0 6px;
    border-radius: 10px;
}

:root {
    --toastify-toast-width: 20vw !important;
    --toastify-toast-min-height: min(7vh, 70px) !important;
    --toastify-toast-max-height: min(2vh, 100px) !important; 
}

.game-code-label {
    font-size: 25px;
}

.game-code:hover {
    cursor: pointer;
    background-color: rgb(46, 165, 75);
}

.info-message {
    font-size: min(1.5vw, 15px);
}

@media screen and (max-width: 600px){
    .game-code {
        font-size: 15px;
    }

    .game-code-label {
        font-size: 15px;
    }
}
@import url('https://fonts.googleapis.com/css?family=Poppins:regular,bold,italic&subset=latin,latin-ext');

.App {
    height: 100vh;
    width: 100vw;
    background: rgb(61, 61, 61);
    font-family: 'Poppins', sans-serif;
    color: rgb(200, 200, 200);
}

.game-title {
    font-size: min(12vw, 100px);
    margin: 0;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.gap {
    gap: 10px;
}